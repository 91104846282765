import React from 'react'
import Page from 'templates/page'
import { graphql, useStaticQuery } from 'gatsby'

var homeQuery = graphql`
  query HomeQuery {
    gcms {
      page(where: { slug: "home" }) {
        title
        slug
        category
        carousel {
          caption
          image {
            url
          }
        }
        content {
          html
        }
        sections {
          title
          content {
            html
          }
          video
          img {
            image {
              url
            }
            alt
          }
          forms
        }
      }
    }
  }
`

export default () => {
  var data = useStaticQuery(homeQuery)
  var pageContext = {
    page: data.gcms.page,
  }

  return <Page pageContext={pageContext} />
}
